import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/roger-gauthier.jpg";
  const fullName = "Roger Gauthier";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const zoomMeetingPasscode = "";
  const birthDate = "October 16, 1944";
  const deathDate = "December 6, 2020";
  const memorialDate = "Monday December 21st, 2020";
  const memorialTime = "10am EST";
  const inviteText = "";
  const obituary = `Beloved husband to Debbie (Woods) for 45 years, Roger is survived by his son Alain (Sylvie Leblanc), daughter Maxime (Stephen Burt), and grandson 13 year-old Guillaume (Gauthier), as well as his younger sister Lise (Pierre Huneault) and her two daughters: Nathalie (Huneault) and Karine (Huneault).
  \nRoger went to work after high school for CBC where he trained as a film editor while attending St-Paul University at night. In the early 1970s, he left the Lanark Street Plant to become an Independent 16mm Film Cameraman.
  \nTen years later, Roger rejoined the CBC in Toronto to shoot and edit News and Public Affairs Programs on video (Betacam). In 1995 Roger was recruited to the Technical Department of the CBC Radio Network. He ended his career as Executive Director of Network Radio Operations.
  \nAfter 2003, he enjoyed several more years of fishing for salmon in Lake Ontario and catching shrimp in the Florida Keys. Aware his health was failing, Roger and Debbie returned to the Ottawa-area five years ago and lived there until his passing last Sunday afternoon.
  \n
  \nÉpoux bien-aimé de Debbie (Woods) depuis 45 ans, Roger laisse dans le deuil son fils Alain (Sylvie Leblanc), sa fille Maxime (Stephen Burt), et son petit-fils Guillaume (Gauthier), âgé de 13 ans, ainsi que sa sœur cadette Lise (Pierre Huneault) et ses deux filles : Nathalie (Huneault) et Karine (Huneault).
  \nRoger est allé travailler après le lycée pour la CBC où il a suivi une formation de monteur de films tout en fréquentant l’Université Saint-Paul le soir. Au début des années 1970, il quitte l’usine de Lanark Street pour devenir caméraman indépendant de films 16 mm.
  \nDix ans plus tard, Roger rejoint la CBC à Toronto pour tourner et monter des émissions d’information et d’affaires publiques en vidéo (Betacam). En 1995, Roger est recruté au département technique du réseau radio de la CBC. Il a terminé sa carrière en tant que directeur exécutif des opérations de la radio du réseau.
  \nAprès 2003, il a passé plusieurs autres années à pêcher le saumon dans le lac Ontario et à capturer des crevettes dans les Keys de Floride. Conscients que sa santé était défaillante, Roger et Debbie sont retournés dans la région d’Ottawa il y a cinq ans et y ont vécu jusqu’à son décès dimanche dernier dans l’après-midi.`;
  const storiesRecordUrl = "https://stories.afterword.com/f670octzi";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/ryhorj88phm88x6ntg3ld2llm5dosy1bqbcoy1n3";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/Roger%20Gauthier%20-%20service.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    >
      <h4>In Lieu of Flowers</h4>
      <p>
        Please donate to{" "}
        <a
          href="https://secure2.convio.net/uohi/site/Donation2?df_id=2903&mfc_pref=T&2903.donation=form1"
          target="_blank"
          rel="noreferrer"
        >
          The University of Ottawa Heart Institute Foundation
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
